import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
// tslint:disable-next-line:nx-enforce-module-boundaries
import {Client, ClientFeatures, User} from '@ttg-web/data';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {

    constructor(
        private _http: HttpClient,
        private _router: Router) {
    }


    login(user: string, pass: string, version?: 'v2'): Observable<User> {
        const url = version ? `${environment.baseUrl}/${version}/login` : `${environment.baseUrl}/login`;
        return this._http.post<any>(url, {userName: user, password: pass});
    }

    loginOtp(user: string, token: string): Observable<User> {
        return this._http.post<any>(`${environment.baseUrl}/v2/login/otp`, {userName: user, token});
    }

    logout(): void {
        localStorage.removeItem('features');
        localStorage.removeItem('userJwt');
        localStorage.removeItem('clientId');
        localStorage.removeItem('dealers');

        this._router.navigate(['/login']);
    }

    passwordRecovery(user: string): Observable<any> {
        return this._http.post<any>(`${environment.baseUrl}/password-recovery`, {userName: user});
    }

    resetPassword(token: string, newPassword: string, confirmPassword: string, securityCode: string): Observable<any> {
        return this._http.put<any>(`${environment.baseUrl}/reset-password`, {token, newPassword, confirmPassword, securityCode});
    }

    getUserClient(clientId: string): Observable<Client> {
        return this._http.get<Client>(`${environment.baseUrl}/client/${clientId}`);
    }

    public getClientFeatures(clientId: string): Observable<ClientFeatures[]> {
        return this._http.get<ClientFeatures[]>(`${environment.baseUrl}/client/${clientId}/features`);
    }


}
