import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {DMSMapping} from '../dms-mapping.model';
import {DMVMapping} from '../dmv-mapping.model';
import {Client, Dealer} from '@ttg-web/data';

@Injectable({
    providedIn: 'root'
})
export class DealerService {

    constructor(private _http: HttpClient) {
    }


    public getDealersByClientId(clientId: string): Observable<any> {
        const params = new HttpParams().set('clientId', clientId);
        return this._http.get<Dealer[]>(`${environment.apiBaseUrl}/client/${clientId}/dealers`, {params});
    }

    public getDeletedDealersByClientId(clientId: string): Observable<any> {
        return this._http.get<Dealer[]>(`${environment.apiBaseUrl}/client/${clientId}/dealers/deleted`);
    }

    public getDealerById(clientId: string, dealerId: string): Observable<Dealer> {
        return this._http.get<Dealer>(`${environment.apiBaseUrl}/client/${clientId}/dealers/${dealerId}`);
    }

    public getAllDealers(): Observable<Dealer[]> {
        return this._http.get<Dealer[]>(`${environment.apiBaseUrl}/dealers`);
    }

    public createDealer(clientId: string, newDealer: Dealer): Observable<Dealer> {
        return this._http.post<Dealer>(`${environment.apiBaseUrl}/client/${clientId}/dealers`, newDealer);
    }

    public duplicateDealer(dealerId: number, body: any): Observable<Dealer> {
        return this._http.post<Dealer>(`${environment.apiBaseUrl}/dealer/${dealerId}/duplicate`, body);
    }

    public deleteDealer(dealerId: number): Observable<any> {
        return this._http.delete<any>(`${environment.apiBaseUrl}/dealers/${dealerId}`);
    }

    public updateDealerNew(dealer: Dealer, clientId: string): Observable<Dealer> {
        return this._http.put<Dealer>(`${environment.apiBaseUrl}/client/${clientId}/dealers/${dealer.id}`, dealer);
    }

    public restoreDealer(dealerId: string): Observable<Dealer> {
        return this._http.put<Dealer>(`${environment.apiBaseUrl}/dealers/${dealerId}/restore`, {});
    }

    public getCounty(zipcode: string): Observable<any> {
        return this._http.get<any>(`${environment.apiBaseUrl}/address/${zipcode}`);
    }

    public getDMSMapping(clientId: string, dealerId: string): Observable<DMSMapping> {
        return this._http.get<DMSMapping>(`${environment.integrationUrl}/clients/${clientId}/dealers/${dealerId}/mapping`);
    }

    public getDMVMapping(clientId: string, dealerId: string): Observable<DMVMapping> {
        return this._http.get<DMVMapping>(`${environment.integrationUrl}/clients/${clientId}/dealers/${dealerId}/dmv-mapping`);
    }

    public createMapping(clientId: string, dealerId: string, newMapping: DMSMapping): Observable<DMSMapping> {
        return this._http.post<DMSMapping>(`${environment.integrationUrl}/clients/${clientId}/dealers/${dealerId}/mapping`, newMapping);
    }

    public createDMVMapping(clientId: string, dealerId: string, newMapping: DMVMapping): Observable<DMVMapping> {
        return this._http.post<DMVMapping>(`${environment.integrationUrl}/clients/${clientId}/dealers/${dealerId}/dmv-mapping`, newMapping);
    }

    public updateMapping(clientId: string, dealerId: string, newMapping: DMSMapping): Observable<DMSMapping> {
        return this._http.put<DMSMapping>(`${environment.integrationUrl}/clients/${clientId}/dealers/${dealerId}/mapping`, newMapping);
    }

    public updateDMVMapping(clientId: string, dealerId: string, newMapping: DMVMapping): Observable<DMVMapping> {
        return this._http.put<DMVMapping>(`${environment.integrationUrl}/clients/${clientId}/dealers/${dealerId}/dmv-mapping`, newMapping);
    }

    public uploadDeals(dealerId: string | number, file): Observable<any> {
        return this._http.post(`${environment.apiBaseUrl}/dealer/${dealerId}/deals/bulk`, file);
    }

}
