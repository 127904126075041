<div class="logo-bg" fxFlexFill fxLayout="row" fxLayoutAlign="center middle" style="align-items: center;">
    <div fxLayout="column" fxLayoutAlign="center middle">
        <mat-card class="mat-elevation-z5" class="container-info">

            <div class="login-logo">
                <img width="200" src="./assets/new-ttg-logo.png">
            </div>

            <mat-card-content>

                <div class="login-text">
                    <h3>
                        SELECT CLIENT
                    </h3>
                    <!-- <p>
                        Page description lorem ipsum dolor sit amet.
                    </p> -->
                </div>

                <ul class="client-holder" *ngIf="(clients$ | async) as clients">
                    <mat-list-item class="client" style="margin-top: 10px" *ngFor="let client of clients">
                        <div class="client-information-holder">
                            <div class="client-information">
                                <h4>{{client.corporationName}}</h4>
                                <p>{{client.corporateAddress.street}} | {{client.corporateAddress.state}}</p>
                            </div>

                            <div class="client-select">
                                <button (click)="navigateToClientContext(client.id)" class="btn lines-only"
                                        style="position:relative;width: 40px; height: 40px;">
                                <span style="position:absolute;top: 11px;left:12px;">
                                    <svg width="16" height="16" viewBox="0 0 16 17" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M1 9.50021H12.17L7.29 14.3802C6.9 14.7702 6.9 15.4102 7.29 15.8002C7.68 16.1902 8.31 16.1902 8.7 15.8002L15.29 9.21021C15.68 8.82021 15.68 8.19021 15.29 7.80021L8.71 1.20021C8.32 0.810215 7.69 0.810215 7.3 1.20021C6.91 1.59021 6.91 2.22021 7.3 2.61021L12.17 7.50021H1C0.45 7.50021 0 7.95021 0 8.50021C0 9.05021 0.45 9.50021 1 9.50021Z"
                                              fill="#ED1D24"/>
                                        </svg>                                        
                                </span>
                                </button>
                            </div>
                        </div>
                    </mat-list-item>
                </ul>
                <div *ngIf="!(clients$ | async)">
                    <mat-progress-spinner style="margin: auto; padding-top: 16px; padding-bottom: 16px;"
                                          [color]="'warn'" [mode]="'indeterminate'"></mat-progress-spinner>
                </div>
                <div style="text-align: center;">
                    <button class="btn lines-only" (click)="logout()">
                        LOG OUT
                    </button>
                </div>

            </mat-card-content>
        </mat-card>
    </div>
</div>
