import {HttpClient} from '@angular/common/http';
import {Client} from '@ttg-web/data';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class ClientService {

    constructor(private _http: HttpClient) {
    }

    getActiveClients(): Observable<Client[]> {
        return this._http.get<Client[]>(`${environment.baseUrl}/clients`);
    }
}
