import {Component, OnInit} from '@angular/core';
import {Client} from '@ttg-web/data';
import {Observable} from 'rxjs';
import {ClientService} from './client.service';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {AuthenticationService} from '../../authentication/service/authentication.service';
import {DealerService} from '../../../../../TTGAdmin/src/app/dealer/services/dealer.service';

@Component({
    selector: 'regstation-client-selection',
    templateUrl: './client-selection.component.html',
    styleUrls: ['./client-selection.component.css']
})
export class ClientSelectionComponent implements OnInit {

    clients$: Observable<Client[]>;

    constructor(private _clientService: ClientService,
                private _dealerService: DealerService,
                private _route: ActivatedRoute,
                private _router: Router,
                private _service: AuthenticationService) {
    }

    ngOnInit(): void {
        this.clients$ = this._clientService.getActiveClients();
        if (this._route.snapshot.queryParams.fetchFeaturesFrom) {
            this.setFeatures(this._route.snapshot.queryParams.fetchFeaturesFrom);
        }
    }

    navigateToClientContext(id: string) {
        this.setFeatures(id);
        localStorage.setItem('clientId', id);

    }

    setFeatures(clientId: string) {
        const featuresList: string[] = [];
        this._service.getClientFeatures(clientId).subscribe(features => {
            features.forEach(feature => {
                featuresList.push(feature.code);
            });
            localStorage.setItem(`${clientId}-features`, featuresList.toString());
            if (this._route.snapshot.queryParams.reroute) {
                window.location = this._route.snapshot.queryParams.reroute;
            } else {
                // this._router.navigate(['/app', clientId, 'dashboard', 'performance']);
                this._router.navigate(
                  [
                    '/app',
                    clientId,
                    'registrations',
                    'list',
                  ],
                  { queryParams: { showCompleted: 'false', skip: 0, take: 50 } }
                );
            }
        });
    }

    logout(): void {
        this._service.logout();
    }
}
