import {Dealer} from './dealer.model';

export class User {
    id: string;
    clientId: string;
    email: string;
    userName: string;
    firstName: string;
    lastName: string;
    lastLoginDate: Date;
    password: string;
    confirmPassword?: string;
    token: string;
    role: string;
    isTtgEmployee: boolean;
    status: string;
    dealers: any;
}
