import {NgModule} from '@angular/core';
import {RootComponent} from './root.component';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthenticationComponent} from '../authentication/authentication.component';
import {AuthenticationGuard} from '../authentication/authentication.guard';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {RootRoutesModules} from './root.routes';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpHeadersInterceptor} from '../http-headers.interceptor';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {ClientSelectionComponent} from './client-selection/client-selection.component';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {ClientService} from './client-selection/client.service';
import {UserService} from '../user/service/users.service';
import {FlexModule} from '@angular/flex-layout';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {LoginGuard} from '../authentication/login.guard';
import {  MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import {NgOtpInputModule} from 'ng-otp-input';

@NgModule({
    declarations: [RootComponent, AuthenticationComponent, ClientSelectionComponent],

    imports: [
        BrowserAnimationsModule,
        RouterModule,
        CommonModule,
        HttpClientModule,
        RootRoutesModules,
        ReactiveFormsModule,
        NgxSmartModalModule.forRoot(),
        MatCardModule,
        MatIconModule,
        MatListModule,
        FlexModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        NgOtpInputModule
    ],

    providers: [
        UserService,
        ClientService,
        AuthenticationGuard,
        LoginGuard,
        {provide: HTTP_INTERCEPTORS, useClass: HttpHeadersInterceptor, multi: true},
        {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
    ],

    bootstrap: [RootComponent]


})
export class RootModule {
}
