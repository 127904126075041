import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';


@Injectable()

export class AuthenticationGuard implements CanActivate {
    constructor(
        private _router: Router
    ) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let jwt = localStorage.getItem('userJwt');
        jwt = jwt ? jwt : route.queryParams.jwt;
        let clientId = localStorage.getItem('clientId');
        if (route.params.clientId) {
            clientId = route.params.clientId;
            localStorage.setItem('clientId', clientId);
        }

        const helper = new JwtHelperService();
        const user = helper.decodeToken(jwt);

        if (!jwt) {
            this._router.navigate(['/login']);
        } else if (user && user.role !== 'Super Admin'
            && user.role !== 'Admin'
            && !user.dealers.some(dealer => `${dealer.clientId}` === route.params.clientId)) {
            localStorage.removeItem('userJwt');
            this._router.navigate(['/login']);
        } else if (!clientId) {
            this._router.navigate(['/client-selection']);
        }
        
        return !!jwt && !!clientId;
    }
}
