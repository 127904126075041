export class Status {
    id: string;
    position: number;
    internalName: string;
    name: string;
    requiredWhenTTGServiceHired: boolean;
    isRequired: boolean;
    allowEdit: boolean;
    sendToInquiryBoard: boolean;
    isAccounting: boolean;
    isComplete: boolean;
    isAtCounty: boolean;
}
