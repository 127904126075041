import {Address} from './address.model';

export class Client {
    id: string;
    corporationName: string;
    corporationEmail: string;
    emailDomain: string;
    billingMail: string;
    billingPhone: string;
    corporateAddress = new Address();
    billingAddress = new Address();
    logoUrl: string;
}
