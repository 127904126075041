// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    baseUrl: 'https://core-api-qa.thetitlegirl.com',
    env: 'qa',
    firebase: {
        apiKey: 'AIzaSyD4ODrV6GZ5sbg57VkCecQkoJQRjy-rggc',
        authDomain: 'rip---qa.firebaseapp.com',
        projectId: 'rip---qa',
        storageBucket: 'rip---qa.appspot.com',
        messagingSenderId: '710028156504',
        appId: '1:710028156504:web:1f905130d9f70fa429342d',
        measurementId: 'G-Z9V61P4WX0'
    },
    features: {
        mfaLogin: false,
        bulkStatusUpdate: true,
        performanceDashboard: true,
        executiveDashboard: true,
        optionalFields: true,
        tradeIn: true,
        batch: true,
        printableDocs: true,
        reconciliation: true,
        tempTag: true
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
import {UserService} from '../app/user/service/users.service';
