<div class="logo-bg" fxFlexFill fxLayout="row" fxLayoutAlign="center middle">
    <div fxLayout="column" fxLayoutAlign="center middle">
        <mat-card class="mat-elevation-z5 container-info" *ngIf="login && !(userName$ | async)"
                  [ngClass]="{'active': !active === true}">

            <div class="login-logo">
                <img width="300" src="./assets/new-ttg-logo.png">
            </div>

            <mat-card-content>

                <div class="login-text">
                    <h3>
                        LOGIN
                    </h3>
                    <p>
                        Please enter your login credentials for Registration Impact Portal.
                    </p>
                </div>
                <div *ngIf="errorMessage" class="error-message">
                    <p>
                        {{errorMessage}}
                    </p>
                </div>

                <form [formGroup]="loginForm">
                    <mat-form-field appearance="outline" fxFill>
                        <mat-label>Email</mat-label>
                        <input formControlName="user" matInput>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFill>
                        <mat-label>Password</mat-label>
                        <input [type]="hide ? 'password' : 'text'" type="password" formControlName="pass" matInput>
                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                </form>
                <div class="login-actions">
                    <div class="forgot-password" *ngIf="!loading">
                        <a (click)="setScreen(false , true , false)">Forgot my password</a>
                    </div>

                    <div class="login-button">
                        <button (click)="onSubmit()" [disabled]="loading || !loginForm.valid" class="btn btn-primary">
                            <span *ngIf="loading"></span>
                            Login
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="mat-elevation-z5" *ngIf="(userName$ | async) as userName">

            <div class="login-logo">
                <img width="300" src="./assets/new-ttg-logo.png">
            </div>

            <mat-card-content>

                <div class="login-text">
                    <h3>
                        CONFIRM TOKEN
                    </h3>
                    <p>
                        Enter the token you received on your email <b style="font-weight: bolder">{{userName}}</b>
                    </p>
                </div>
                <div *ngIf="errorMessage" class="error-message">
                    <p>
                        {{errorMessage}}
                    </p>
                </div>

                <div style="padding-left: 57px;padding-bottom: 45px;" fxFill>
                    <mat-label>Token</mat-label>
                    <ng-otp-input (onInputChange)="tokenInformed($event)" [config]="{length:6}"></ng-otp-input>
                </div>

                <div class="login-actions">
                    <div class="forgot-password" *ngIf="!loading">
                        <a [routerLink]="['/login']">Back to Login screen</a>
                    </div>

                    <div class="login-button">
                        <button (click)="onSubmitToken(userName)" class="btn btn-primary" [disabled]="!(validToken && validToken.length === 6)">
                            <span *ngIf="loading"></span>
                            Login
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="mat-elevation-z5" *ngIf="forgotPassword" class="container-info"
                  [ngClass]="{'active': !active === true}">

            <div class="login-logo">
                <img width="300" src="./assets/new-ttg-logo.png">
            </div>

            <mat-card-content>

                <div class="login-text">
                    <h3>
                        FORGOT MY PASSWORD
                    </h3>
                    <p>
                        Please enter your email address for TTG Registration Station. If you are in our system, you will
                        get an email with the password reset link in the next 15 minutes. </p>
                </div>
                <form [formGroup]="resetPasswordForm">
                    <mat-form-field appearance="outline" fxFill>
                        <mat-label>E-mail</mat-label>
                        <input type="password" formControlName="userName" type="email" placeholder="User e-mail"
                               matInput>
                        <mat-error *ngIf="resetPasswordForm.get('userName').invalid">Please enter a valid email
                        </mat-error>
                    </mat-form-field>
                </form>


                <div>
                    <div *ngIf="errorMessage" style="text-align: center;margin-bottom: 16px;">
                        <p style="color: red;"> {{errorMessage}}</p>
                    </div>
                    <div class="login-button" style="display: flex; justify-content: flex-end;">
                        <button (click)="setScreen(true , false, false)" [disabled]="loading" class="btn lines-only"
                                style="margin-right: 16px;">
                            Cancel
                        </button>
                        <button (click)="sendResetRequest()" [disabled]="isDisabled()" class="btn btn-primary">
                            <span *ngIf="loading"></span>
                            Send password reset link
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="mat-elevation-z5" *ngIf="resetSuccess" class="container-info"
                  [ngClass]="{'active': !active === true}">

            <div class="login-logo">
                <img width="300" src="./assets/new-ttg-logo.png">
            </div>

            <mat-card-content>

                <div class="login-text">
                    <h3>
                        Success
                    </h3>
                    <p>
                        Your password has been updated, go to login and try it!
                    </p>
                </div>


                <div>

                    <div class="login-button" style="text-align: center;">
                        <button (click)="setScreen(true, false, false)" [disabled]="loading" class="btn lines-only">
                            Go to login
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="mat-elevation-z5" *ngIf="changePassword" class="container-info"
                  [ngClass]="{'active': !active === true}">
            <form [formGroup]="resetChangePasswordForm">
                <div class="login-logo">
                    <img width="300" src="./assets/new-ttg-logo.png">
                </div>

                <mat-card-content>

                    <div class="login-text">
                        <h3>
                            Change your password
                        </h3>
                        <p>
                            You just received a security code on your e-mail, and it will expire in 5 minutes.
                        </p>

                        <mat-form-field appearance="outline" fxFill>
                            <mat-label>Security code</mat-label>
                            <input type="text" formControlName="securityCode" matInput>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFill>
                            <mat-label>Password</mat-label>
                            <input type="password" formControlName="password" matInput>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFill>
                            <mat-label>Repeat your password</mat-label>
                            <input type="password" formControlName="passwordRepeat" matInput>
                        </mat-form-field>
                    </div>

                    <div>
                        <div *ngIf="errorMessage" style="text-align: center;margin-bottom: 16px;">
                            <p style="color: red;"> {{errorMessage}}</p>
                        </div>
                        <div class="login-button" style="text-align: center;">
                            <button (click)="resetPassword()" [disabled]="loading" class="btn lines-only">
                                Reset your password
                            </button>
                        </div>
                    </div>
                </mat-card-content>
            </form>
        </mat-card>

        <mat-card class="mat-elevation-z5" *ngIf="changePasswordLinkExpired" class="container-info"
                  [ngClass]="{'active': !active === true}">

            <div class="login-logo">
                <img width="300" src="./assets/new-ttg-logo.png">
            </div>

            <mat-card-content>

                <div class="login-text">
                    <h3>
                        Change password link expired
                    </h3>
                    <p>
                        You can enter your email address for TTG Registration Station. If you are in our system, you
                        will get an email with the new password reset link in the next 15 minutes. </p>
                </div>
                <form [formGroup]="resetPasswordForm">
                    <mat-form-field appearance="outline" fxFill>
                        <mat-label>E-mail</mat-label>
                        <input type="password" formControlName="userName" type="email" placeholder="User e-mail"
                               matInput>
                        <mat-error *ngIf="resetPasswordForm.get('userName').invalid">Please enter a valid email
                        </mat-error>
                    </mat-form-field>
                </form>


                <div>
                    <div *ngIf="errorMessage" style="text-align: center;margin-bottom: 16px;">
                        <p style="color: red;"> {{errorMessage}}</p>
                    </div>
                    <div class="login-button" style="display: flex; justify-content: flex-end;">
                        <button (click)="setScreen(true , false, false)" [disabled]="loading" class="btn lines-only"
                                style="margin-right: 16px;">
                            Cancel
                        </button>
                        <button (click)="sendResetRequest()" [disabled]="isDisabled()" class="btn btn-primary">
                            <span *ngIf="loading"></span>
                            Send password reset link
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
