import {Dealer} from '@ttg-web/data';
import {Client} from '@ttg-web/data';
import {Registration} from '../../../../apps/reg-station/src/app/registrations/registration';

export class TempTag {
    id: string;
    dealerId: number;
    vin: string;
    status: string;
    customerName: string;
    zipCode: string;
    county: string;
    tagCount: string;
    dueDate: Date;
    mvpa: string;
    insurance: string;
    inspection: string;
    poa: string;
    driverLicense: string;
    applicationVTR66: string;
    plates: string[];
    dealer: Dealer;
    client: Client;
    createdAt: Date;
    updatedAt: Date;
    tagUploadCount: number;

    static fromRegistration(registration: Registration): TempTag {
        const t = new TempTag();
        t.dealerId = registration.dealerId;
        t.vin = registration.vin;
        t.customerName = registration.customerName;
        t.zipCode = registration.zipCode;
        t.county = registration.county;
        t.dealer = registration.dealer;
        return t;
    }
}
