import {HttpErrorResponse, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from './authentication/service/authentication.service';


@Injectable({
    providedIn: 'root'
})
export class HttpHeadersInterceptor implements HttpInterceptor {

    constructor(private _router: Router, private _auth: AuthenticationService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let jwt = localStorage.getItem('userJwt');

        let newHeaders = new HttpHeaders();
        if (jwt) {
            newHeaders = req.headers.append('Authorization', jwt);
        }

        if (!newHeaders.has('Content-Type')) {
            newHeaders = newHeaders.append('Content-Type', 'application/json');
        } else if (newHeaders.get('Content-Type') === 'file-upload') {
            newHeaders = newHeaders.delete('Content-Type');
        }

        if (!newHeaders.has('Accept')) {
            newHeaders = newHeaders.append('Accept', 'application/json');
        }

        const authReq = req.clone({
            headers: newHeaders
        });

        return next.handle(authReq).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error && error.status === 401) {
                    this._auth.logout();
                } else {
                    return next.handle(authReq);
                }
            })
        );
    }

}
