import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthenticationComponent} from '../authentication/authentication.component';
import {AuthenticationGuard} from '../authentication/authentication.guard';
import {ClientSelectionComponent} from './client-selection/client-selection.component';
import {LoginGuard} from '../authentication/login.guard';
import { LogoutComponent } from '../logout/logout.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {path: `login`, component: AuthenticationComponent, canActivate: [LoginGuard]},
            {path: `logout`, component: LogoutComponent},
            {path: `client-selection`, component: ClientSelectionComponent},
            {
                path: `app/:clientId`,
                canActivate: [AuthenticationGuard],
                loadChildren: () => import('../app.module').then(m => m.AppModule)
            },
            //TODO guard index step
            {path: '', redirectTo: '/login', pathMatch: 'full'}
        ])
    ],
    exports: [
        RouterModule
    ]
})

export class RootRoutesModules {
}
