import {Injectable} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {HttpHeadersInterceptor} from '../../../../../TTGAdmin/src/app/http-headers.interceptor';
import {User} from '@ttg-web/data';
import {Observable, Subject} from 'rxjs';
import {environment} from 'apps/reg-station/src/environments/environment';
import {isThisISOWeek} from 'date-fns';
import {Dealer} from '@ttg-web/data';
import {Params} from '@angular/router';
import {downloadBlob} from '../../../shared/download.utils';


@Injectable({
    providedIn: 'root'
})
export class UserService {

    _loggedUser: User;
    userCreated = new Subject<any>();

    constructor(private _http: HttpClient,
                private _auth: HttpHeadersInterceptor,
                loggedUser: User, private _modalService: NgxSmartModalService,) {
        this._loggedUser = loggedUser;
    }


    getClientUsers(): Observable<User[]> {
        const params = new HttpParams().set('clientId', this._loggedUser.clientId);
        return this._http.get<User[]>(`${environment.baseUrl}/client/${this._loggedUser.clientId}/user`, {params});
    }


    getSelectedUsers(userId: string): Observable<User> {
        const params = new HttpParams().set('id', userId);
        return this._http.get<User>(`${environment.baseUrl}/client/${this._loggedUser.clientId}/user`, {params});
    }

    openUserModal(userId?: string): void {
        if (userId) {
            this._modalService.resetModalData('userModal');
            this._modalService.setModalData(userId, 'userModal');
        } else {
            this._modalService.resetModalData('userModal');
        }
        this._modalService.getModal('userModal').open(true);

    }

    openClearenceModal(userId: string): void {
        this._modalService.resetModalData('clearenceModal');
        this._modalService.setModalData({id: userId}, 'clearenceModal');
        this._modalService.getModal('clearenceModal').open(true);
    }


    userClearence(userInfo) {
        return this._http.post<User>(`${environment.baseUrl}/user/${userInfo.id}/clearance`, userInfo);
    }

    createUser(newUser: User): Observable<User> {
        const params = new HttpParams().set('clientId', this._loggedUser.clientId);
        return this._http.post<User>(`${environment.baseUrl}/client/${this._loggedUser.clientId}/user`, newUser, {params});
    }

    updateUser(userUpdate: User): Observable<User> {
        const params = new HttpParams().set('clientId', this._loggedUser.clientId);
        return this._http.put<User>(`${environment.baseUrl}/client/${this._loggedUser.clientId}/user/${userUpdate.id}`, userUpdate, {params});
    }

    deleteUser(userId: string): Observable<User> {
        const params = new HttpParams().set('clientId', this._loggedUser.clientId);
        return this._http.delete<User>(`${environment.baseUrl}/client/${this._loggedUser.clientId}/user/${userId}`, {params});
    }

    public getDealersByClient(): Observable<Dealer[]> {
        return this._http.get<Dealer[]>(`${environment.baseUrl}/client/${this._loggedUser.clientId}/dealers`);
    }

    public getUserHistory(userId: string): Observable<any> {
        return  this._http.get<any>(`${environment.baseUrl}/user/${userId}/login-logs`);
    }

    downloadUsersReport(paramsObj?: any): void {
        let headers = new HttpHeaders();
        headers = headers.append('Accept', 'text/csv');
        const params = new HttpParams({fromObject: paramsObj})
            .append('clientId', this._loggedUser.clientId)
            .append('tzOffset', new Date().getTimezoneOffset().toString());
        this._http.get(`${environment.baseUrl}/users/`, {
            params,
            headers,
            responseType: 'blob'
        })
            .subscribe(csv => {
                downloadBlob(csv, 'users-report.csv');
            });

    }

    changePassword(currentPassword: string, newPassword: string, confirmPassword: string): Observable<any> {
        return this._http.put<any>(`${environment.baseUrl}/client/${this._loggedUser.clientId}/user-password`, {
            currentPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword
        });
    }

    sendMessage(): void {
        this.userCreated.next();
    }

    assumeRole(user): Observable<User> {
        return this._http.get<User>(`${environment.baseUrl}/v1/users/${user.id}/assume-role`);
    }
}
