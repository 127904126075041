import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from './service/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as crypto from 'crypto-js';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent implements OnInit {

    loginForm: FormGroup;
    errorMessage: string;
    submitted: boolean;
    loading = false;
    login = false;
    forgotPassword = false;
    resetSuccess = false;
    active = false;
    changePassword = false;
    changePasswordLinkExpired = false;
    hide = true;
    resetPasswordForm: FormGroup;
    resetChangePasswordForm: FormGroup;
    validToken: string;
    userName$: Observable<string>;

    constructor(private _authenticationService: AuthenticationService,
                private _router: Router,
                private _route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.userName$ = this._route.queryParams.pipe(map(params => params.email));
        this.setupForm();
        this.setupResetForm();
        this.login = true;
    }


    setupForm(): void {
        this.loginForm = new FormGroup({
            user: new FormControl('', Validators.required),
            pass: new FormControl('', Validators.required)
        });
    }

    setupResetPasswordForm(): void {
        this.resetChangePasswordForm = new FormGroup({
            securityCode: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
            passwordRepeat: new FormControl('', Validators.required)
        });
    }

    setupResetForm(): void {
        this.resetPasswordForm = new FormGroup({
            userName: new FormControl('', Validators.compose([Validators.email, Validators.required])),
        });
    }

    resetPassword(): void {
        const securityCode = this.resetChangePasswordForm.get('securityCode').value;
        const newPass = crypto.SHA1(this.resetChangePasswordForm.get('password').value).toString();
        const newRpeatPass = crypto.SHA1(this.resetChangePasswordForm.get('passwordRepeat').value).toString();

        this._authenticationService.resetPassword(this.validToken, newPass, newRpeatPass, securityCode).subscribe(
            data => {
                this.setScreen(false, false, true, false, false);
                this.loading = false;
            },
            error => {
                this.errorMessage = error.error.message;
                this.loading = false;
            }
        );
    }

    setScreen(loginScren: boolean, forgotScreen: boolean,
              successScreen: boolean, changePassword: boolean,
              changePasswordLinkExpired: boolean) {
        this.loading = true;
        this.active = !this.active;
        this.errorMessage = null;
        this.setupForm();
        this.setupResetForm();
        this.setupResetPasswordForm();
        setTimeout(() => {
            this.forgotPassword = forgotScreen;
        }, 200);
        setTimeout(() => {
            this.resetSuccess = successScreen;
        }, 200);
        setTimeout(() => {
            this.login = loginScren;
        }, 200);
        setTimeout(() => {
            this.loading = false;
        }, 250);
        setTimeout(() => {
            this.active = !this.active;
        }, 250);
        setTimeout(() => {
            this.changePassword = changePassword;
        }, 200);
        setTimeout(() => {
            this.changePasswordLinkExpired = changePasswordLinkExpired;
        }, 200);
    }


    onSubmit(): void {
        if (environment.features.mfaLogin) {
            return this.onSubmitWith2fa();
        }
        return this._onSubmit();
    }

    onSubmitWith2fa(): void {
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        let user = this.loginForm.value.user;
        let encryptedPass = crypto.SHA1(this.loginForm.value.pass).toString();
        this._authenticationService.login(user, encryptedPass, 'v2').subscribe(data => {
                this._router.navigate([], {queryParams: {email: data.userName}});
            }, error => {
                this.errorMessage = error.error.message;
                this.loading = false;
            }
        );
    }

    _onSubmit(): void {
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        let user = this.loginForm.value.user;
        let encryptedPass = crypto.SHA1(this.loginForm.value.pass).toString();
        this._authenticationService.login(user, encryptedPass).subscribe(data => {
                localStorage.setItem('userJwt', data.token);
                if (data.role === 'Super Admin' || data.role === 'Admin') {
                    this._router.navigate(['client-selection']);
                } else {
                    this.setFeatures(data.dealers[0].clientId);
                    localStorage.setItem('clientId', data.dealers[0].clientId);
                    localStorage.setItem('dealers', JSON.stringify(data.dealers));
                }
                this.loading = false;
            }, error => {
                this.errorMessage = error.error.message;
                this.loading = false;
            }
        );
    }


    setFeatures(clientId: string) {
        const featuresList: string[] = [];
        this._authenticationService.getClientFeatures(clientId).subscribe(features => {
            features.forEach(feature => {
                featuresList.push(feature.code);
            });
            // localStorage.setItem('features', featuresList.toString());
            localStorage.setItem(`${clientId}-features`, featuresList.toString());
            this._router.navigate(['/app', clientId]);
        });

    }

    sendResetRequest(): void {
        this.loading = true;
        this._authenticationService.passwordRecovery(this.resetPasswordForm.get('userName').value).subscribe(user => {
                this.setScreen(false, false, false, true, false);
            },
            error => {
                console.error(error.error[0].msg);
                this.errorMessage = error.error[0].msg;
                this.loading = false;
            });
    }


    isDisabled(): boolean {
        if (this.loading) {
            return true;
        } else {
            if (this.resetPasswordForm.valid) {
                return false;
            } else {
                return true;
            }

        }
    }

    onSubmitToken(userName: string) {
        this._authenticationService.loginOtp(userName, this.validToken).subscribe(
            data => {
                localStorage.setItem('userJwt', data.token);
                if (data.role === 'Super Admin' || data.role === 'Admin') {
                    this._router.navigate(['client-selection']);
                } else {
                    this.setFeatures(data.dealers[0].clientId);
                    localStorage.setItem('clientId', data.dealers[0].clientId);
                    localStorage.setItem('dealers', JSON.stringify(data.dealers));
                }
                this.loading = false;
            },
            error => {
                this.errorMessage = error.error.message;
                console.error(error);
                this.loading = false;

            }
        );
    }

    tokenInformed(token: string) {
        this.validToken = token;
    }
}
