import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';


@Injectable()
export class LoginGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _cookieService: CookieService,
    ) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const jwt = localStorage.getItem('userJwt');
        let clientId = localStorage.getItem('clientId');
        if (jwt && clientId) {
            this._router.navigate(['/app', clientId]);
            return false;
        }
        return true;
    }
}
