import {Address, Status} from '@ttg-web/data';
import {OptionalField} from './optional-fields';


export class Dealer {
    id: number;
    name: string;
    dealerName?: string;
    dealerNumber: string;
    type: string;
    dealerType?: string;
    contactName: string;
    contactMail: string;
    statusList: Status[];
    dealerStatus: Status[];
    clientId: string;
    zipCode: string;
    address: Address;
    hasTTGService: boolean;
    ttgServiceFee: number;
    duplicateTitleRequestServiceFee: number;
    optionalFields?: OptionalField[];
    tradeInMissingTitleInstructions: string;
}
