export function downloadBlob(blob: any, fileName: string) {

    let newBlob = new Blob([blob], {type: 'text/csv'});

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob, fileName);
        return;
    }

    const data = window.URL.createObjectURL(newBlob);

    let link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

    setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
    }, 100);

}
